export enum ButtonSizes {
	XS = 'XS',
	S = 'S',
	M = 'M',
	L = 'L',
	XL = 'XL',
	ICON = 'ICON',
}

export enum ButtonSizeClasses {
	XS = 'text-xs px-4 py-2',
	S = 'text-xs px-4 py-2.5',
	M = 'text-sm px-4 py-2.5',
	L = 'text-sm px-4 py-3',
	XL = 'text-sm px-4 py-3.5',
	ICON = 'flex justify-center items-center text-2xl w-10 h-10',
}

export enum ButtonStyleTypes {
	CTA = 'CTA',
	DELETE = 'DELETE',
	DELETE_OUTLINE = 'DELETE_OUTLINE',
	PRIMARY = 'PRIMARY',
	SECONDARY = 'SECONDARY',
	STROKE = 'STROKE',
	TERTIARY = 'TERTIARY',
	LINK = 'LINK',
	LINK_BLUE = 'LINK_BLUE',
}

export enum StyleClasses {
	CTA = 'border-2 border-yellow-500 bg-yellow-500 text-purple-500 hover:border-purple-500 hover:bg-purple-500 hover:text-white focus:border-purple-500 focus:bg-yellow-500 focus:text-purple-500 disabled:border-grey-100 disabled:bg-grey-100 disabled:text-grey-400',
	DELETE = 'border-2 border-feedback-error-500 bg-feedback-error-500 text-white hover:border-feedback-error-700 hover:bg-feedback-error-700 hover:text-white focus:border-purple-500 focus:bg-feedback-error-500 focus:text-white disabled:border-grey-100 disabled:bg-grey-100 disabled:text-grey-400',
	DELETE_OUTLINE = 'border-2 border-feedback-error-500 text-feedback-error-500 hover:border-feedback-error-700 hover:text-feedback-error-700 focus:border-purple-500 focus:text-purple-500 disabled:border-grey-100 disabled:text-grey-400',
	PRIMARY = 'border-2 border-purple-500 bg-purple-500 text-white hover:border-yellow-500 hover:bg-yellow-500 hover:text-purple-500 focus:border-yellow-500 focus:bg-purple-500 focus:text-white disabled:border-grey-100 disabled:bg-grey-100 disabled:text-grey-400',
	SECONDARY = 'border-2 border-purple-50 bg-purple-50 text-purple-500 hover:border-purple-500 hover:bg-purple-500 hover:text-white focus:border-purple-500 focus:bg-purple-50 focus:text-purple-500 disabled:border-grey-100 disabled:bg-grey-100 disabled:text-grey-400',
	STROKE = 'border-2 border-purple-500 text-purple-500 hover:border-yellow-500 hover:text-purple-500 focus:border-purple-500 focus:text-purple-500 focus:outline focus:outline-yellow-200 disabled:border-grey-100 disabled:text-grey-400',
	TERTIARY = 'border border-grey-300 bg-white text-grey-700 hover:border hover:border-purple-500 hover:outline hover:outline-2 hover:outline-purple-200 hover:bg-grey-50 hover:text-grey-700 focus:border-purple-500 focus:bg-white focus:text-grey-700 disabled:border-grey-100 disabled:bg-grey-100 disabled:text-grey-400',
	LINK = 'text-purple-500 hover:bg-purple-50 focus:bg-purple-50 focus:outline-purple-500 disabled:text-grey-400',
	LINK_BLUE = 'text-blue-500 hover:bg-blue-50 focus:bg-blue-50 focus:outline-blue-500 disabled:text-grey-400',
}

export enum ButtonTypes {
	BUTTON = 'button',
	SUBMIT = 'submit',
}
